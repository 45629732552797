import { motion } from "framer-motion";
import React from "react";
import TopImage from "../../components/TopImage";
import img195e26f8c2c262eda16b1d830b597db1 from "../../img/195e26f8c2c262eda16b1d830b597db1.jpg";

export default function AttentionFraud() {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
    >
      <TopImage
        image={img195e26f8c2c262eda16b1d830b597db1}
        title={"Внимание мошенничество"}
      />
      <div className="page-grid__content" id="content">
        <div class="text-area">
          <p>
            <b>Уважаемые посетители сайта и клиенты АО «Мособлэнерго»! </b>
          </p>
          <b> </b>
          <p>
            <b>Будьте внимательны!</b>
          </p>
          <p>
            На территории Московской области фиксируются случаи мошеннических
            действий в отношении потенциальных клиентов и контрагентов АО
            «Мособлэнерго».
          </p>
          <p>
            Пользуясь популярностью бренда АО «Мособлэнерго», мошенники
            предлагают в короткие сроки и при определенных вложениях
            организовать сотрудничество с компанией, обещая успешное прохождение
            любой закупочной процедуры с последующим заключением крупных
            договоров с АО «Мособлэнерго».
          </p>
          <p>
            Обращаем внимание, что закупочные процедуры, направленные на
            организацию поставки товаров, выполнения работ и оказания услуг для
            нужд компании проводятся в строгом соответствии с Федеральным
            законом от 18.07.2011г. № 223-ФЗ «О закупках товаров, работ, услуг
            отдельными видами юридических лиц», Конституцией Российской
            Федерации, Гражданским кодексом Российской Федерации, и иными
            нормативными правовыми актами Российской Федерации.
          </p>
          <p>
            При осуществлении закупочных процедур АО «Мособлэнерго» следует
            принципам информационной открытости и прозрачности. Компания
            обеспечивает честную конкуренцию, предоставляя всем потенциальным
            контрагентам возможность участия на равных основаниях.
          </p>
          <p>
            Еще один вид мошеннических действий, который применяется в отношении
            деятельности компании, связан с оказанием посреднических услуг по
            технологическому присоединению к электросетям АО «Мособлэнерго».
            Мошенники предлагают ускорить данный процесс, упростить и без того
            прозрачный порядок подачи документов. АО «Мособлэнерго» настоятельно
            рекомендует игнорировать сомнительные коммерческие предложения от
            организаций, предлагающих посреднические услуги по подключению к
            электросетям. Результатом услуг таких посредников может стать лишь
            серьезный материальный ущерб. Обращаем внимание, что благодаря
            заметным шагам АО «Мособлэнерго» по упрощению процедуры
            технологического присоединения и сокращению сроков подключений, Вам
            не составит труда самостоятельно подать заявку и оформить
            необходимые документы.
          </p>
          <p>
            Не реагируйте на предложения частных лиц и сомнительных организаций,
            предлагающих посреднические услуги по обеспечению сотрудничества с
            АО «Мособлэнерго». Напрямую обращайтесь в «Мособлэнерго», а по
            вопросам технологического присоединения - в любой из наших
            клиентских офисов или консультируйтесь у специалистов компании.
            Избегайте сотрудничества с потенциальными мошенниками.
          </p>
          <p>
            {/* Для обеспечения безопасности наших клиентов и контрагентов мы собираем информацию о распространенных схемах мошенничества в отношении деятельности АО «Мособлэнерго». Если Вам стало известно о подобной противоправной деятельности, сообщите
            об этом нам: */}
            АО «Мособлэнерго» принимает все необходимые меры для обеспечения
            безопасности наших клиентов и контрагентов, передача персональных
            данных, таких как номер паспорта, номер СНИЛС и т.п., по телефону
            НЕВОЗМОЖНА! Сообщать любым посторонним лицам цифровые коды,
            поступившие на ваши телефоны или почту, категорически запрещено!
          </p>
          {/* <p>
            Тел.: <b>8(495)780-39-62, доб.3336; 3339; 2136; 1015</b>
          </p> */}
        </div>
      </div>
    </motion.div>
  );
}
